import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import es_ES from 'antd/lib/locale-provider/es_ES';

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";



import Table from 'antd/lib/table';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import DatePicker from 'antd/lib/date-picker';
import Tag from 'antd/lib/tag';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Modal from 'antd/lib/modal';
import Divider from 'antd/lib/divider';

import { getTransacciones , getBancos , getEmpresas , getMetodos } from '../../network/restClient.js';
import TransaccionObj from './Model.js';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const Option = Select.Option;


class Transacciones extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      loading:false,
      loadingEmpresas:false,
      loadingMetodos:false,
      transacciones: [],
      empresas: [],
      totalTransacciones:0,
      size:10,
      page:1,
      filterEmpresas: [],
      filterBancos: [],
      filterMetodos: [],
      transaccionSeleccionada: {},
      visibleCode: false,
      fields: {
        fecha_desde_registro:"",
        fecha_hasta_registro:"",
        nombre_metodos:[],
        id_bancos:[],
        codigo_orden:"",
        id_empresas:[],
        request:"",
        response:"",
        estado:"",
      	order_by:{index:"id",order:"desc"}
      }
    };
  }

  componentDidMount(){
    this.loadTransacciones();
    this.loadEmpresas();
    this.getMetodos();
    this.loadBancos();
  }

  loadBancos(){
    getBancos()
      .then(response=>{
          let filterBancos = [];
          response.bancos.map(e=>{
            filterBancos.push({value:e.id_entidad_bancaria,text:e.nombre_corto.toUpperCase()});
          });
          this.setState({filterBancos:filterBancos});
      })
      .catch(error=>{
      })
  }

  loadEmpresas = ()=>{
    this.setState({loadingEmpresas:true});
    getEmpresas().then(response=>{
      let filterEmpresas = [];
      response.empresas.map(e=>{
        filterEmpresas.push({value:e.id_empresa,text:e.razon_social.toUpperCase()});
      })
      this.setState({loadingEmpresas:false,empresas:response.empresas,
                    filterEmpresas:filterEmpresas});
    }).catch(err=>{
      this.setState({loadingEmpresas:false});
    })
  }

  getMetodos = () =>{
    this.setState({loadingMetodos:true});
    getMetodos().then(response=>{
      let filterMetodos = [];
      response.metodos.map(e=>{
        filterMetodos.push({value:e.nombre.toUpperCase(),text:e.nombre.toUpperCase()});
      })
      this.setState({loadingMetodos:false,filterMetodos:filterMetodos});
    }).catch(err=>{
      this.setState({loadingMetodos:false});
    })
  }
  showCode = (transaccion) => {
      this.setState({
        transaccionSeleccionada: transaccion,
        visibleCode: true,
      });
  }

  handleOk = (e) => {
    this.setState({
      visibleCode: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visibleCode: false,
    });
  }

  loadTransacciones = ()=>{
    if(!this.state.loading){
        this.setState({loading:true});
        var filtros = this.state.fields;
        filtros.size = this.state.size;
        filtros.page = this.state.page;

        getTransacciones(filtros).then(response=>{
          let tr = []
          response.transacciones.map(t=>{
            tr.push(new TransaccionObj(t));
          })
          this.setState({transacciones:tr,loading:false,totalTransacciones:Number(response.total)});
        }).catch(err=>{
          this.setState({loading:false});
        })
    }
  }

  handleSearchCodigo = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.codigo_orden = selectedKeys[0];
    this.loadTransacciones();
  }

  handleResetCodigo = clearFilters => () => {
    clearFilters();
    this.state.fields.codigo_orden = "";
    this.loadTransacciones();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(values.rangosFecha!= undefined){
          if(values.rangosFecha.length==2){
            this.state.fields.fecha_desde_registro = values.rangosFecha[0].format('YYYY-MM-DD');
            this.state.fields.fecha_hasta_registro = values.rangosFecha[1].format('YYYY-MM-DD');
          }else{
            this.state.fields.fecha_desde_registro = "";
            this.state.fields.fecha_hasta_registro = "";
          }
        }

        this.loadTransacciones()
      }
    });
  }

  clearFilters = () => {
    this.setState({ fields: {
          fecha_desde_registro:this.state.fields.fecha_desde_registro,
          fecha_hasta_registro:this.state.fields.fecha_hasta_registro,
          ruc_cliente:"",
          codigo_orden:"",
          id_empresas:[],
          nombre_metodos:[],
          razon_social_cliente:"",
          estado:null,
          order_by:this.state.fields.order_by
        } },()=>{
          this.loadTransacciones();
        });
  }

  handleTableChange = (pagination, filters, sorter) => {
      /*--------------------    Filtros --------------------- */
        if(filters['estadoLabel']){
          this.state.fields.estado = filters['estadoLabel'][0];
        }
        if(filters['empresa']){
          this.state.fields.id_empresas = filters['empresa'];
        }
        if(filters['metodo']){
          this.state.fields.nombre_metodos = filters['metodo'];
        }
        if(filters['banco']){
          this.state.fields.id_bancos = filters['banco'];
        }
      /*------------------- Ordenamientos ----------------- */
          if(sorter.field === "key"){
            let order = sorter.order === "descend"?"desc":"asc";
            this.state.fields.order_by = {index:"id",order:order}
          }
          if(sorter.field === "metodo"){
            let order = sorter.order === "descend"?"desc":"asc";
            this.state.fields.order_by = {index:"metodo",order:order}
          }
          if(sorter.field === "fechaRegistro"){
            let order = sorter.order === "descend"?"desc":"asc";
            this.state.fields.order_by = {index:"fecha_registro",order:order}
          }

          // Falta f. t. y f. c.
          if(sorter.field === "fechaContable"){
            let order = sorter.order === "descend"?"desc":"asc";
            this.state.fields.order_by = {index:"fecha_contable",order:order}
          }

          if(sorter.field === "empresa"){
            let order = sorter.order === "descend"?"desc":"asc";
            this.state.fields.order_by = {index:"empresa",order:order}
          }
          if(sorter.field === "estadoLabel"){
            let order = sorter.order === "descend"?"desc":"asc";
            this.state.fields.order_by = {index:"estado",order:order}
          }
          if(sorter.field === "tiempoEjecucion"){
            let order = sorter.order === "descend"?"desc":"asc";
            this.state.fields.order_by = {index:"tiempo_ejecucion",order:order}
          }
          if(sorter.field === "banco"){
            let order = sorter.order === "descend"?"desc":"asc";
            this.state.fields.order_by = {index:"banco",order:order}
            // this.loadOrdenesPago();
          }

          if(sorter.field==null)this.state.fields.order_by = {index:"id",order:"desc"};
      /*------------------- paginador ----------------- */
          this.state.page = pagination.current;
          this.state.size = pagination.pageSize;

      this.loadTransacciones();

  }

  onChangePage = (page) => {
    // this.setState({page: page},()=>{
    //   this.loadTransacciones();
    // });
  }

  onResizePage = (current, pageSize) => {
    this.setState({size: pageSize},()=>{
      this.loadTransacciones();
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { empresas , filterBancos , transaccionSeleccionada , filterEmpresas , filterMetodos , fields , loading } = this.state;
    const columns = [
      /* --------------------------- Column No. Transacción ---------------------------------- */
      {
        title: 'No.',
        dataIndex: 'key',
        key: 'key',
        sorter: true
      },
      /* --------------------------- Column CÓDIGO ---------------------------------- */
      {title: 'Orden Pago',
      dataIndex: 'codigoOrden',
      key: 'codigoOrden',
      filteredValue: fields.codigo_orden || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => this.searchInput = ele}
            style={{marginBottom:4}}
            placeholder="Buscar código"
            value={selectedKeys?selectedKeys:null}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={this.handleSearchCodigo(selectedKeys, confirm)}
          />
          <Button type="primary" onClick={this.handleSearchCodigo(selectedKeys, confirm)}>Buscar</Button>
          <Button style={{marginLeft:4}} onClick={this.handleResetCodigo(clearFilters)}>Borrar</Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            this.searchInput.focus();
          });
        }
      },
      render: (text) => {
        const { fields } = this.state;
        return fields.codigo_orden ? (
          <span>
            {text.split(new RegExp(`(?<=${fields.codigo_orden})|(?=${fields.codigo_orden})`, 'i')).map((fragment, i) => (
              fragment.toLowerCase() === fields.codigo_orden.toLowerCase()
                ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
            ))}
          </span>
        ) : text;
      },
    },
    /* --------------------------- Column NOMBRE BANCO ---------------------------------- */
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      sorter: true,
      filters: filterBancos,
      filteredValue: fields.id_bancos || null,
      filterMultiple: true,
      render: (text,item) => {return text.toUpperCase()}
    },
    /* --------------------------- Column Métodos ---------------------------------- */
    {
      title: 'Método',
      dataIndex: 'metodo',
      key: 'metodo',
      sorter: true,
      filters: filterMetodos,
      filteredValue: fields.nombre_metodos || null,
      filterMultiple: true,
      render: (text,item) => {return text?text.toUpperCase():""}
    },
    {
      title: 'F. Registro',
      dataIndex: 'fechaRegistro',
      key: 'fechaRegistro',
      sorter: true
    },
    // {
    //   title: 'Fecha T.',
    //   dataIndex: 'fechaTransaccion',
    //   key: 'fechaTransaccion',
    //   sorter: true
    // },
    {
      title: 'F. Contable',
      dataIndex: 'fechaContable',
      key: 'fechaContable',
      sorter: true
    },
    // {
    //   title: 'Empresa',
    //   dataIndex: 'empresa',
    //   key: 'empresa',
    //   sorter: true,
    //   filters: filterEmpresas,
    //   filteredValue: fields.id_empresas || null,
    //   filterMultiple: true,
    //   render: (text,item) => {return text?text.toUpperCase():""}
    // },
    {
      title: 'Estado',
      dataIndex: 'estadoLabel',
      key: 'estadoLabel',
      sorter: true,
      filters: [{
          text: 'COMPLETADO',
          value: 't',
        }, {
          text: 'INCOMPLETO',
          value: 'f',
        }],
      filterMultiple: false,
      filteredValue: fields.estado || null,
      render: (text,item) => {return (
        <span>
            {item.estado && <Tag color="green">{item.estadoLabel}</Tag>}
            {!item.estado && <Tag color="orange">{item.estadoLabel}</Tag>}
        </span>
      )}
    }, {
      title: 'T. Ejec. (ms)',
      dataIndex: 'tiempoEjecucion',
      key: 'tiempoEjecucion',
      sorter: true,
      render: (valor) => {return valor}
    }, {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (valor,item) => {
        return (<Button onClick={() => this.showCode(item)} icon="code"  type="primary" size="small" shape="circle" />)}
    }];

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={12} sm={12}>
              <Card
                title="Transacciones realizadas"
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div style={{padding:6}}>
                      <Row style={{marginBottom:6}}>
                          <Col xs={12} md={12}>
                              <Form layout="inline" className="rangoFecha" onSubmit={this.handleSubmit}>
                                <FormItem>
                                  {getFieldDecorator('rangosFecha', {})(
                                    <RangePicker locale={es_ES.DatePicker} format='YYYY/MM/DD'/>
                                  )}
                                </FormItem>

                                <FormItem>
                                  <Button type="primary" htmlType="submit" disabled={loading}>
                                    Consultar
                                  </Button>
                                </FormItem>
                                <Button onClick={this.clearFilters} disabled={loading}>Limpiar filtros</Button>
                              </Form>
                          </Col>
                      </Row>
                      <Row>
                          <Col xs={12} md={12}>
                            <Table className="font-table-13" columns={columns}
                              size={"small"}
                              dataSource={this.state.transacciones}
                              loading={this.state.loading}
                              footer={() => this.state.totalTransacciones+ " transacciones"}
                              onChange={this.handleTableChange}
                              pagination={{current:this.state.page,
                                          showSizeChanger:true,
                                          total:this.state.totalTransacciones}} />
                        </Col>
                    </Row>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        <Modal
          width={600}
          title = {
            <span>
                <label style={{marginRight: 10}}>No. {transaccionSeleccionada.key } </label>
                <label>
                    {transaccionSeleccionada.estado && <Tag color="green">{transaccionSeleccionada.estadoLabel}</Tag>}
                    {!transaccionSeleccionada.estado && <Tag color="orange">{transaccionSeleccionada.estadoLabel}</Tag>}
                </label>
            </span>
          }
          onCancel={this.handleCancel}
          visible={this.state.visibleCode}
          footer={[
            <Button key="back" onClick={this.handleOk}>Cerrar</Button>,
          ]}
        >
          <Row>
            <Col xs={6} md={6} lg={6}>
              <label>Método: </label>{transaccionSeleccionada.metodo}
            </Col>
            <Col xs={6} md={6} lg={6}>
              <label>Fecha: </label>{transaccionSeleccionada.fechaRegistro}
            </Col>
          </Row>
          <Row type="flex" justify="center" align="top" className={'text-json'}>
            <Col xs={12} md={6} lg={6}>
                <label>REQUEST</label><br/>
                <div>
                    {transaccionSeleccionada.jsonResquest}
                </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
                <label>RESPONSE</label><br/>
                <div>
                    {transaccionSeleccionada.jsonResponse}
                </div>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
const WrapperTransacciones = Form.create()(Transacciones);
export default WrapperTransacciones;
