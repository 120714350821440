import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import DatePicker from 'antd/lib/date-picker';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import Divider from 'antd/lib/divider';
import message from 'antd/lib/message';
import AutoComplete from 'antd/lib/auto-complete';
import notification from 'antd/lib/notification';

import { getOPbyRecaudacion } from '../../network/restClient';

import { getCodigoContratos } from "../../network/restClient";


const FormItem = Form.Item;
const Option = Select.Option;

class FormRecaudacion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      loading:false,
      codigos: []
    };
  }

  componentDidMount(){
    console.log("ENTRA")
    this.getCodigos();
  }

  getCodigos(){
    getCodigoContratos()
      .then(codigos=>{
        var codArr = [];
        if(codigos){
          codigos.codigos.map(codigo=>{
            codArr.push(codigo.codigo_servicio)
          })
        }
        this.setState({codigos:codArr})
      })
      .catch(err=>{

      })
  }
  consultaOP = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        this.setState({ loading: true });
        getOPbyRecaudacion(values.tipo_producto,values.codigo_obligacion)
        .then(response=>{
          this.setState({ loading: false });
          this.props.emitOP(response);
        })
        .catch(error=>{
          this.setState({ loading: false });
          this.props.emitOP(error);
        })
      }
    });
  }

  render(){
    const { loading } = this.state;

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24 },
        },
    };
    return (
      <Form>
        <Row>
              <Col md={6} sm={6}>
                <FormItem
                    label={'No. Contrato'}
                    required={true}>
                    {getFieldDecorator('tipo_producto', {
                      rules: [{ required: true, message: 'Ingrese el código de contrato' }],
                    })(
                      <Select placeholder="Código de Contrato" style={{ width: 200 }}>
                            {this.state.codigos.map( (r,i) => {
                              return (<Option key={i} value={r}>{r}</Option>)
                            })}
                      </Select>
                      )}
                </FormItem>
              </Col>
              <Col md={6} sm={6}>
                <FormItem
                    label={'Cod. Obligación'}
                    required={true}>
                    {getFieldDecorator('codigo_obligacion', {
                      rules: [{ required: true, message: 'Ingrese el código obligación'}]
                    })(
                      <Input placeholder="Código de Obligación"
                         type="text"
                       />
                   )}
                </FormItem>
              </Col>
          </Row>
          <Row type="flex" justify="end">
              <Col md={12} sm={12}>
                  <Button loading={loading} onClick={this.consultaOP} type="primary" block={true}>Consultar</Button>
              </Col>
          </Row>
      </Form>
    )

  }
}

const WrapperFormRecaudacion = Form.create()(FormRecaudacion);
export default WrapperFormRecaudacion;
