import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import es_ES from 'antd/lib/locale-provider/es_ES';

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";

import Table from 'antd/lib/table';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import DatePicker from 'antd/lib/date-picker';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import Tooltip from 'antd/lib/tooltip';
import Alert from 'antd/lib/alert';
import notification from 'antd/lib/notification';


import { getBancosEmpresa,getDetalleCompensacion } from '../../network/restClient.js';
import FormDownload from './downloadForm';


const FormItem = Form.Item;
const Option = Select.Option;


class ConciliacionObj extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      loading:false,
      conciliacion:undefined,
      bancos:[],
      detalles:[],
      compensacion:undefined,
      errorMessage:"",
      cabeceraDescarga:[
        {label:"RUC",value:"referencia"},
        {label:"TURNO",value:"obligacion_cliente"},
        {label:"VALOR",value:"valor_pagado"},
        {label:"AGENCIA",value:"agencia"},
        {label:"CANAL",value:"canal"},
        {label:"FECHA RECAUDO",value:"fecha_recaudo"},
        {label:"HORA TRANSACCION",value:"hora_transaccion"},
        {label:"FORMA PAGO",value:"forma_pago1"},
        {label:"SECUENCIA",value:"id_secuencia"},
        {label:"NOMBRE",value:"nombre"},
        {label:"VALOR RETENIDO",value:"valor_retenido"}
      ],
      data:[],
      fileName:""
    };
  }

  componentDidMount(){
    this.loadBancosEmpresas();
  }

  loadBancosEmpresas = ()=>{
    getBancosEmpresa().then(response=>{
      let bancos = [];
      response.bancos.map(e=>{
        let banco = e;
        banco.key =`${banco.id_entidad_bancaria}-banco`;
        bancos.push(banco);
      })
      this.setState({bancos:bancos});
    })
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        let consulta ={
          dia:values.fecha.format('YYYY-MM-DD'),
          entidad_bancaria: values.bancos
        }
        this.setState({loading:true,errorMessage:"",detalles:[],compensacion:undefined});
        getDetalleCompensacion(consulta).then(compensacionObj=>{
          let detalles = [];
          let data = [];
          let compensacion = {
            id_compensacion: compensacionObj.compensacion.id_compensacion,
            num_registros: compensacionObj.compensacion.num_registros,
            total_recaudado: compensacionObj.compensacion.total_recaudado
          }
          compensacionObj.compensacion.detalle.map(detalle=>{
            let det = detalle;
            det.key = `${det.id_transaccion}-detalle`;
            detalles.push(det);
            data.push({ruc:det.referencia,turno:det.obligacion_cliente,valor:det.valor_pagado})
          })
          this.state.fileName = "conciliacion-"+consulta.dia;
          this.setState({loading:false,detalles:detalles,compensacion:compensacion,data:data});
        },error=>{
          this.state.fileName = "";
          this.setState({loading:false,errorMessage:error.response.data.message,data:[]});
        })
      }
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={12} sm={12}>
              <Card
                title="Conciliación Bancaria"
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div style={{padding:6}}>
                      <div style={{marginBottom:6,marginLeft:10}}>
                              <Form layout="inline"  onSubmit={this.handleSubmit}>
                                <FormItem>
                                  {getFieldDecorator('bancos', {rules: [{ required: true, message: 'Seleccione la entidad bancaria' }]})(
                                    <Select style={{ minWidth: 240 }} placeholder="Entindad Bancaria">
                                      {this.state.bancos.map(banco=>
                                        <Option key={banco.key} value={banco.id_entidad_bancaria}>{banco.razon_social}</Option>
                                      )}
                                    </Select>
                                  )}
                                </FormItem>
                                <FormItem >
                                  {getFieldDecorator('fecha', {rules: [{ required: true, message: 'Seleccione la fecha' }]})(
                                    <DatePicker />
                                  )}
                                </FormItem>

                                <FormItem>
                                  <Button type="primary" htmlType="submit" disabled={this.state.loading}>
                                    Consultar
                                  </Button>
                                </FormItem>
                              </Form>
                      </div>
                      <Row>
                          <Col xs={12} md={12}>
                              {this.state.compensacion?
                                <div style={{marginTop:20}}>
                                  <Row>
                                    <Col lg={6} md={6} sm={8} xs={12}>
                                      <StatsCard
                                        bigIcon={<i className="pe-7s-graph1 text-success" />}
                                        statsText="Total movimientos"
                                        statsValue={this.state.compensacion.num_registros}
                                        statsIcon={<i className="fa fa-calendar-o" />}
                                        statsIconText={"En la fecha seleccionada"}
                                      />
                                    </Col>
                                    <Col lg={6} md={6} sm={8} xs={12}>
                                      <StatsCard
                                        bigIcon={<i className="pe-7s-wallet text-success" />}
                                        statsText="Total recaudado"
                                        statsValue={"$ "+this.state.compensacion.total_recaudado.toFixed(2)}
                                        statsIcon={<i className="fa fa-calendar-o" />}
                                        statsIconText={"En la fecha seleccionada"}
                                      />
                                    </Col>
                                  </Row>
                                </div>:''}
                                {this.state.errorMessage!=""?<Alert message={this.state.errorMessage} type="warning" />:""}
                                <Row>
                                    <Col md={12}>
                                      <FormDownload
                                        fileName={this.state.fileName}
                                        cabecera={this.state.cabeceraDescarga}
                                         datos={this.state.detalles} />
                                    </Col>
                                </Row>
                              <Table className="font-table-13"
                                columns={[
                                  {
                                    title:"Transacción",
                                    dataIndex:"id_transaccion",
                                    key:"id_transaccion",
                                    render:(text,record)=><span>{parseInt(text)}</span>
                                  },
                                  {
                                    title:"Orden Pago",
                                    dataIndex:"obligacion_cliente",
                                    key:"obligacion_cliente"
                                  },
                                  {
                                    title:"Nombre",
                                    dataIndex:"nombre",
                                    key:"nombre",
                                    render:(text,record)=>{
                                      return (
                                        <span>
                                          {record.nombre}<br/>
                                          {record.referencia}
                                        </span>)
                                    }
                                  },
                                  {
                                    title:"F. Recaudación",
                                    dataIndex:"fecha_recaudo",
                                    key:"fecha_recaudo",
                                    render:(text,record)=>{
                                      return (<span>{record.fecha_recaudo} {record.hora_transaccion.substring(0,5)}</span>)
                                    }
                                  },
                                  {
                                    title:"Canal",
                                    dataIndex:"canal",
                                    key:"canal",
                                    render:(text,record)=>{
                                      return (<span>{record.canal}</span>)
                                    }
                                  },
                                  {
                                    title:"Valor Pagado ($)",
                                    dataIndex:"valor_pagado",
                                    key:"valor_pagado",
                                    render:(text,record)=><span>{text.toFixed(2)}</span>
                                  }
                                ]}
                                size={"small"}
                                dataSource={this.state.detalles}
                                loading={this.state.loading}
                                footer={() => this.state.detalles.length+ " movimientos"}
                                pagination={false} />
                        </Col>
                    </Row>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
const Conciliacion = Form.create()(ConciliacionObj);
export default Conciliacion;
