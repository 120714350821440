import React, { Component } from 'react';

import InputNumber from 'antd/lib/input-number';
import Switch from 'antd/lib/switch';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import message from 'antd/lib/message';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Input from 'antd/lib/input';

import Workbook from '../../components/Workbook';
import PropTypes from 'prop-types';

class FormDownloadObj extends Component{
  static propTypes = {
    datos: PropTypes.array.isRequired,
    cabecera: PropTypes.array.isRequired
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      downloading: false,
      readytoDownload:false,
      hash:0
    }
  }

  handleDownload = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      this.setState({
        readytoDownload:false,
      });
      if (!err) {
        this.setState({
          readytoDownload:true,
          hash: this.randomHash()
        });
      }
    });
  }
  randomHash(){
    const min = 1;
    const max = 100;
    const rand = min + Math.random() * (max - min);
    return rand;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { downloading } = this.state;
    const FormItem = Form.Item;
    const Option = Select.Option;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <div key={this.state.hash}>
      {this.state.readytoDownload?
        <Workbook filename={this.props.fileName?this.props.fileName+".xlsx":"conciliaciones.xlsx"}>
          <Workbook.Sheet data={this.props.datos} name="datos">
            {this.props.cabecera.map(function(column, index){
              return (
                <Workbook.Column label={column.label} value={column.value}/>
              );
            },"")}
          </Workbook.Sheet>
        </Workbook>
      :''}

      <Form onSubmit={this.handleDownload}>

        <FormItem style={{marginBottom:0}}
          wrapperCol={{ span: 4, offset: 20 }}
        >
          <Button disabled={this.props.datos.length===0} loading={this.state.downloading} type="primary" htmlType="submit">Descargar</Button>
        </FormItem>
      </Form>
      </div>
    );
  }

}
const FormDownload = Form.create()(FormDownloadObj);
export default FormDownload;
