export default class ClienteObj  {
  constructor(id_orden_pago, ruc, razon_social, total,
              id_empresa, empresa) {
    this.key = id_orden_pago;
    this.id_orden_pago = id_orden_pago;
    this.ruc = ruc;
    this.razonSocial = razon_social;
    this.total = total;
    this.idEmpresa = id_empresa;
    this.empresa = empresa;
  }
}
