export default class OrdenPagoObj  {
  constructor(id, codigo, ruc_cliente, razon_social_cliente, banco, valor, estado,
              fecha_registro, id_empresa, empresa, revisado, canal) {
    this.key = id;
    this.codigo = codigo;
    this.rucCliente = ruc_cliente;
    this.razonSocialCliente = razon_social_cliente;
    this.banco = banco;
    this.valor = valor;
    this.estado = estado;
    this.fecha = fecha_registro;
    this.idEmpresa = id_empresa;
    this.empresa = empresa;
    this.revisado = revisado;
    this.loadingCheck = false;
    this.canal = canal;
  }

  getEstadoLabel(){return this.estado==="P"? "PAGADO":"REVERSADO";}

  checkOrdenPago(){
    console.log(this)
    // this.loadingCheck = true;
  }
}
