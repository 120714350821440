import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import es_ES from 'antd/lib/locale-provider/es_ES';

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";

import Table from 'antd/lib/table';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import DatePicker from 'antd/lib/date-picker';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import { getClientes , getEmpresas } from '../../network/restClient.js';
import ClienteObj from './Model.js';
import OPClientes from './OPCliente'

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const cltion = Select.cltion;

class Clientes extends Component {
  constructor(prcls) {
    super(prcls);
    this.state = {
      searchText: '',
      loading:false,
      loadingEmpresas:false,
      clientes: [],
      empresas: [],
      detalleCliente: false,
      totalCli:0,
      size:10,
      page:1,
      clienteSeleccionado: {},
      filterEmpresas: [],
      fields: {
        fecha_desde:"",
        fecha_hasta:"",
        ruc_cliente:"",
        razon_social_cliente:"",
        id_empresa:null,
        id_empresas:[],
      	order_by:{index:'id',order:"desc"}
      },
      defaultSortOrder:{index:'id',order:"descend"}
    };
  }
  componentDidMount(){
    this.loadClientes();
    this.loadEmpresas();
  }

  loadEmpresas = ()=>{
    this.setState({loadingEmpresas:true});
    getEmpresas().then(response=>{
      let filterEmpresas = [];
      response.empresas.map(e=>{
        filterEmpresas.push({value:e.id_empresa,text:e.razon_social.toUpperCase()});
      })
      this.setState({loadingEmpresas:false,empresas:response.empresas,
                    filterEmpresas:filterEmpresas});
    }).catch(err=>{
      this.setState({loadingEmpresas:false});
    })
  }

  loadClientes = ()=>{
    if(!this.state.loading){
        this.setState({loading:true});
        var filtros = this.state.fields;
        filtros.size = this.state.size;
        filtros.page = this.state.page;

        getClientes(filtros).then(response=>{
          let cls = []
          response.clientes.map(cl=>{
            cls.push(new ClienteObj(cl.id_orden_pago,
                              cl.ruc_cliente,
                              cl.razon_social,
                              Number(cl.total),
                              cl.id_empresa,
                              cl.empresa));
          })
          this.setState({clientes:cls,loading:false,totalCli:Number(response.total)});
        }).catch(err=>{
          this.setState({loading:false});
        })
    }
  }
  handleSearchRuc = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.ruc_cliente = selectedKeys[0];
    this.loadClientes();
  }

  handleResetRuc = clearFilters => () => {
    clearFilters();
    this.state.fields.ruc_cliente = "";
    this.loadClientes();
  }

  handleSearchNombre = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.razon_social_cliente = selectedKeys[0];
    this.loadClientes();
  }

  handleResetNombre = clearFilters => () => {
    clearFilters();
    this.state.fields.razon_social_cliente = "";
    this.loadClientes();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        if(values.rangosFecha!= undefined){
          if(values.rangosFecha.length==2){
            this.state.fields.fecha_desde = values.rangosFecha[0].format('YYYY-MM-DD');
            this.state.fields.fecha_hasta = values.rangosFecha[1].format('YYYY-MM-DD');
          }else{
            this.state.fields.fecha_desde = "";
            this.state.fields.fecha_hasta = "";
          }
        }
        this.loadClientes()
      }
    });
  }
  clearFilters = () => {
    this.setState({ fields: {
          fecha_desde:this.state.fields.fecha_desde,
          fecha_hasta:this.state.fields.fecha_hasta,
          ruc_cliente:"",
          razon_social_cliente:"",
          id_empresas:[],
          order_by:this.state.fields.order_by
        } },()=>{
          this.loadClientes();
        });
  }

  handleTableChange = (pagination, filters, sorter) => {
      /*--------------------    Filtros --------------------- */
      if(filters['empresa']){
        this.state.fields.id_empresas = filters['empresa'];
      }
      if(sorter.field === "fecha"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:'id',order:order}
        // this.loadClientes();
      }
      /*------------------- Ordenamientos ----------------- */
      if(sorter.field === "razonSocial"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"nombre",order:order}
        // this.loadClientes();
      }
      if(sorter.field === "total"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"total",order:order}
        // this.loadClientes();
      }
      if(sorter.field === "empresa"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"empresa",order:order}
        // this.loadClientes();
      }
      if(sorter.field==null){
        this.state.fields.order_by = {index:"id",order:"desc"}
        this.state.defaultSortOrder = {index:null,order:null};
      }else{
        this.state.defaultSortOrder = {index:sorter.field,order:sorter.order};
      }
      /*------------------- paginador ----------------- */
      this.state.page = pagination.current;
      this.state.size = pagination.pageSize;

      this.loadClientes();
  }

  onChangePage = (page) => {
    this.setState({page: page},()=>{
      this.loadClientes();
    });
  }

  onResizePage = (current, pageSize) => {
    this.setState({size: pageSize},()=>{
      this.loadOrdenesPago();
    });
  }

  verDetalle = ( cliente ) =>{
    console.log(cliente);
    this.setState({detalleCliente:true,
                  clienteSeleccionado:cliente});
  }
  ocultarDetalle = () =>{
    console.log("SORTER",this.state.defaultSortOrder);
    this.setState({detalleCliente:false,
                  clienteSeleccionado:{}});
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { defaultSortOrder, empresas , clienteSeleccionado , filterEmpresas , fields , loading , detalleCliente } = this.state;
    const columns = [
    /* --------------------------- Column RUC  ---------------------------------- */
    {title: 'Ruc',
      dataIndex: 'ruc',
      key: 'ruc',
      filteredValue: fields.ruc_cliente || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => this.searchInputRuc = ele}
            style={{marginBottom:4}}
            placeholder="Buscar RUC"
            value={selectedKeys?selectedKeys:null}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={this.handleSearchRuc(selectedKeys, confirm)}
          />
          <Button type="primary" onClick={this.handleSearchRuc(selectedKeys, confirm)}>Buscar</Button>
          <Button style={{marginLeft:4}} onClick={this.handleResetRuc(clearFilters)}>Borrar</Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            this.searchInputRuc.focus();
          });
        }
      },
      render: (text,item) => {
        const { fields } = this.state;
        return (
          <a onClick={()=> this.verDetalle(item)}>
                {fields.ruc_cliente?
                  (<span>
                    {text.split(new RegExp(`(?<=${fields.ruc_cliente})|(?=${fields.ruc_cliente})`, 'i')).map((fragment, i) => (
                      fragment.toLowerCase() === fields.ruc_cliente.toLowerCase()
                        ? <span key={i} className="highlight">{fragment}</span> : fragment
                    ))}
                  </span>):
                  text}

          </a>)
      }
    },
    /*
    <a onClick={()=> this.verDetalle(item)}>
    return fields.ruc_cliente ?
    (<span>
        {text.split(new RegExp(`(?<=${fields.ruc_cliente})|(?=${fields.ruc_cliente})`, 'i')).map((fragment, i) => (
          fragment.toLowerCase() === fields.ruc_cliente.toLowerCase()
            ? <span key={i} className="highlight">{fragment}</span> : fragment
        ))}
      </span>
    ) : text;
    */
    /* --------------------------- Column NOMBRE ---------------------------------- */
    {title: 'Nombre',
      dataIndex: 'razonSocial',
      key: 'razonSocial',
      sorter: true,
      filteredValue: fields.razon_social_cliente || null,
      defaultSortOrder: defaultSortOrder.index == 'razonSocial' ? defaultSortOrder.order:null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => this.searchInputNombre = ele}
            style={{marginBottom:4}}
            placeholder="Buscar nombre"
            value={selectedKeys?selectedKeys:null}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={this.handleSearchNombre(selectedKeys, confirm)}
          />
          <Button type="primary" onClick={this.handleSearchNombre(selectedKeys, confirm)}>Buscar</Button>
          <Button style={{marginLeft:4}} onClick={this.handleResetNombre(clearFilters)}>Borrar</Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            this.searchInputNombre.focus();
          });
        }
      },
      render: (text) => {
        const { fields } = this.state;
        return fields.razon_social_cliente ? (
            <span>
                {text.split(new RegExp(`(?<=${fields.razon_social_cliente})|(?=${fields.razon_social_cliente})`, 'i')).map((fragment, i) => (
                  fragment.toUpperCase() === fields.razon_social_cliente.toUpperCase()
                    ? <span key={i} className="highlight">{fragment}</span> : fragment
                ))}
              </span>) : text;
      }
    },
    // {
    //   title: 'Empresa',
    //   dataIndex: 'empresa',
    //   key: 'empresa',
    //   sorter: true,
    //   filters: filterEmpresas,
    //   filteredValue: fields.id_empresas || null,
    //   filterMultiple: true,
    //   render: (text,item) => {return text.toUpperCase()}
    // },
    {
      title: 'Total Recaudado ($)',
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      defaultSortOrder: defaultSortOrder.index == 'total' ? defaultSortOrder.order:null,
      render: (valor) => {return valor.toFixed(2)}
    }];
    return (
      <div className="content">
        {!detalleCliente && <Grid fluid>
          <Row>
            <Col lg={12} sm={12}>
              <Card
                title="Clientes"
                category="Órdenes de pago de los clientes"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div style={{padding:6}}>
                      <Row style={{marginBottom:6}}>
                          <Col xs={12} md={12}>
                              <Form layout="inline" className="rangoFecha" onSubmit={this.handleSubmit}>
                                <FormItem>
                                  {getFieldDecorator('rangosFecha', {})(
                                    <RangePicker locale={es_ES.DatePicker} format='YYYY/MM/DD'/>
                                  )}
                                </FormItem>

                                <FormItem>
                                  <Button type="primary" htmlType="submit" disabled={loading}>
                                    Consultar
                                  </Button>
                                </FormItem>
                                <Button onClick={this.clearFilters} disabled={loading}>Limpiar filtros</Button>
                              </Form>
                          </Col>
                      </Row>
                      <Row>
                          <Col xs={12} md={12}>
                            <Table className="font-table-13" columns={columns}
                              size={"small"}
                              dataSource={this.state.clientes}
                              loading={this.state.loading}
                              footer={() => this.state.totalCli+ " órdenes de pago"}
                              onChange={this.handleTableChange}
                              pagination={{current:this.state.page,
                                          showSizeChanger:true,
                                          total:this.state.totalCli}} />
                        </Col>
                    </Row>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
        }

        {detalleCliente && <Grid fluid>
          <Row>
            <Col lg={12} sm={12}>
              <Card
                title={<div>
                        <Button onClick={this.ocultarDetalle} size="small" type="default" style={{marginRight:7, top:-3}}>
                          <Icon type="left" />Volver
                        </Button>
                        Órdenes de Pago del cliente
                      </div>}
                        ctTableFullWidth
                        ctTableResponsive
                        content={

                  <div style={{padding:6}}>
                      <Row>
                        <Col md={6} className="clienteLabelsSeleccion">
                            <p><span>Razón social: </span>{clienteSeleccionado.razonSocial}</p>
                            <p><span>Ruc: </span>{clienteSeleccionado.ruc}</p>
                        </Col>
                        <Col md={6} className="clienteLabelsSeleccion">
                            <span>TOTAL: </span>$ {clienteSeleccionado.total.toFixed(2)}
                        </Col>
                      </Row>
                      <Row style={{marginBottom:6}}>

                          <Col xs={12} md={12}>
                              <OPClientes
                                  filterEmpresas={filterEmpresas}
                                  fechaDesde={fields.fecha_desde}
                                  fechaHasta={fields.fecha_desde}
                                  rucCliente={clienteSeleccionado.ruc}
                                />
                          </Col>
                      </Row>
                  </div>}
              />
            </Col>
          </Row>
        </Grid>}

      </div>
    );
  }
}
const WrapperClientes = Form.create()(Clientes);
export default WrapperClientes;
