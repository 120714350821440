import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";


import Table from 'antd/lib/table';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import CardA from 'antd/lib/card';
import RowA from 'antd/lib/row';
import ColA from 'antd/lib/col';
import Alert from 'antd/lib/alert';
import Select from 'antd/lib/select';
import Radio from 'antd/lib/radio';
import Popover from 'antd/lib/popover';


import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import { getResumeTransacciones , getResumeRecaudado , getBancos , getFiveClientes , getCodigoContratos } from "../../network/restClient";
import WrapperFormRecaudacion from './FormRecaudacion';
import GraphData from './Model';
import GraphData2 from './Model2';
import TopClientes from './TopClientes.js';
import {timeFormatDefaultLocale,timeFormat} from 'd3-time-format';
import * as format from "d3-format";

import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "variables/Variables.jsx";

import 'react-vis/dist/style.css';
import {
        makeWidthFlexible,
        CanvasComponent,
        XYPlot,
        XAxis,
        YAxis,
        VerticalGridLines,
        VerticalBarSeries,
        VerticalBarSeriesCanvas,
        HorizontalGridLines,
        LineMarkSeriesCanvas,
        LineMarkSeries,
        LineSeriesCanvas,
        LineSeries,
        Crosshair} from 'react-vis';

timeFormatDefaultLocale({
    dateTime    : '%a %b %e %X %Y',
    date        : '%d/%m/%Y',
    time        : '%H : %M : %S',
    periods     : ['AM', 'PM'],
    days        : ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
    shortDays   : ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
    months      : ['Enero', 'Ferbrero', 'Marzo', 'Abril', 'Mayo', 'Jumio', 'Juilio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    shortMonths : ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
});

const FlexibleXYPlot = makeWidthFlexible(XYPlot);

const Option = Select.Option;


const ranges = [{value:"30-dias",label:"Últimos 30 días"},
                {value:"15-dias",label:"Últimos 15 días"},
                {value:"mes",label:"Este mes"},
                {value:"*",label:"Desde el principio"}];

const data = [
      {x: 0, y: 8},
      {x: 1, y: 5},
      {x: 2, y: 4},
      {x: 3, y: 9},
      {x: 4, y: 1},
      {x: 5, y: 7},
      {x: 6, y: 6},
      {x: 7, y: 3},
      {x: 8, y: 2},
      {x: 9, y: 0}
    ];

const colorRanges = {
  typeA: ['#59E4EC', '#0D676C'],
  typeB: ['#EFC1E3', '#B52F93']
};

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visibleResponse:false,
      responseConsulta: {},
      error:{show:false,message:""},
      bancos:[],
      bancoSeleccionado:[],
      botonBancoSeleccionado:0,
      range:ranges[3],
      totalTransacciones:{pagos:0,consultas:0,reversos:0},
      totalPagos:0,
      transacciones:[],
      pagos:[],
      graphPagos:new GraphData([],"",["Recaudación"],"$"),
      graphPagosTotal:new GraphData2([],[],[]),
      graphTransacciones:new GraphData([],"",["Consultas","Pagos","Reversos"],"$"),
      clientes:[],
      topClientes:new TopClientes([],[]),
      value: false,
      colorType: 'typeA',
      strokeWidth: 1,
      showMarks: true,
      hideComponent: false
    };
  }

  componentDidMount(){
    this.getDashboardBancos();
  }

  setDataDashboard(){
    this.getTransacciones();
    this.getRecaudado();
    this.getFiveClientes();
  }

  getDashboardBancos(){
    getBancos()
      .then(response=>{
          var values = [];
          response.bancos.map(b=>{
            values.push({id:b.id_entidad_bancaria,value:b.nombre_corto});
          });

          this.setState({bancos:response.bancos,
                        bancoSeleccionado:values,
                        botonBancoSeleccionado: 0},()=>{
            this.setDataDashboard();
          });
      })
      .catch(error=>{
      })
  }

  getTransacciones(){
    getResumeTransacciones(this.state.range.value,this.state.bancoSeleccionado)
      .then(response=>{
          // var grafico = new GraphData(response.transacciones,"TRANSACCIONES",["Consultas","Pagos","Reversos"],"");
          // console.log(grafico)
          // this.setState({transacciones:response.transacciones_total,graphTransacciones:grafico},() => this.setTotalesTransacciones());
          this.setState({transacciones:response.transacciones_total},() => this.setTotalesTransacciones());
      })
      .catch(error=>{

      })
  }
  getRecaudado(){
    getResumeRecaudado(this.state.range.value,this.state.bancoSeleccionado)
      .then(response=>{
        console.log("ENTRA",response.ordenes_pago[0].ordenes_pago);
          // var grafico = new GraphData(response.ordenes_pago,"REACAUDADO",["Recaudación"],"$");
          // var grafico2 = new GraphData2(response.ordenes_pago);
          var graficoTotal = new GraphData2(response.ordenes_pago_total,response.ordenes_pago,this.state.bancos);
          this.setState({pagos:response.ordenes_pago_total,graphPagosTotal:graficoTotal},() => this.setTotalRecaudado());
      })
      .catch(error=>{

      })
  }

  getFiveClientes(){
    getFiveClientes(this.state.range.value,this.state.bancoSeleccionado)
      .then(response=>{
          var clientes = response.clientes;
          var topClientes = new TopClientes(response.clientes,this.state.bancos);
          console.log(topClientes);
          this.setState({clientes:clientes,topClientes:topClientes});
      })
      .catch(error=>{

      })
  }

  setTotalesTransacciones(){
    var consultas = 0;
    var pagos = 0;
    var reversos = 0;
    this.state.transacciones.map(trans=>{
      consultas += Number(trans.consultas);
      reversos += Number(trans.reversos);
      pagos += Number(trans.pagos);
    });
    this.setState({totalTransacciones:{
                                consultas:consultas,
                                reversos:reversos,
                                pagos:pagos
                              }});
  }

  setTotalRecaudado(){
    var total = 0;
    this.state.pagos.map(pago=>{
      total += Number(pago.total);
    });
    this.setState({totalPagos:total.toFixed(2)});
  }

  handleChange = (value) => {
    var selectRange = ranges.find(function(r){return r.value === value})
    this.setState({range:selectRange},()=>{
      this.setDataDashboard();
    })
  }

  handleChangeBanco = (value) => {
    var values = [];
    if(value==0){
      this.state.bancos.map(b=>{
        values.push({id:b.id_entidad_bancaria,value:b.nombre_corto});
      })
    }else{
      var tmp = this.state.bancos.filter(function(b){if(b.id_entidad_bancaria==value)return b;});
      console.log(tmp);
      values = [{id:tmp[0].id_entidad_bancaria,value:tmp[0].nombre_corto}];
    }

    this.setState({bancoSeleccionado:values,
                  botonBancoSeleccionado: value},()=>{
      this.getTransacciones();
      this.getRecaudado();
      this.getFiveClientes();
    });
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  saveFormOP = (formRef) => {
    this.formOPRef = formRef;
  }
  consultaOP = (response) => {
      this.setState({responseConsulta:response.body,visibleResponse:true});

      if(response!=undefined){
            if(response.Codigo_Respuesta=="000"){
              this.setState({error:{show:false,message:""},responseConsulta:response});
            }else{
              this.setState({error:{show:true,message:response.Mensaje_Respuesta}});
            }
          }
    else{
        this.setState({error:{show:true,message:"El código de obligación no es correcto"}});
      }
  }

  handleOk = (e) => {
    this.setState({
      visibleResponse: false,
    });
  }

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visibleResponse: false,
    });
  }

  getItem = (d) => {
    var data = [];
    if(this.state.botonBancoSeleccionado == 0)
      data= this.state.graphPagosTotal.getData(d[0].x);

    data.push({title:"Total",value:"$"+d[0].y});
    return data;
  }

  handleBotonBancoChange = (e) => {
    this.setState({ botonBancoSeleccionado: e.target.value });
  }

  getComponentTotal(totalBanco){
    const content = (
      <div>
        {totalBanco.map(t=> {return (<p>{t.banco}: $ {t.total.toFixed(2)}</p>)})}
      </div>
    );
    return content;
  }

  render() {
    const BarSeries = VerticalBarSeriesCanvas;

    const { bancos,bancoSeleccionado, botonBancoSeleccionado ,
      visibleResponse , range , topClientes ,
      graphPagos , graphPagosTotal , graphTransacciones , totalTransacciones ,
      clientes , responseConsulta , totalPagos , value , colorType,
      drawMode,
      hideComponent,
      strokeWidth,
      showMarks} = this.state;

    const lineSeriesProps = {
      animation: true,
      className: 'mark-series-example',
      // sizeRange: [5, 15],
      color: colorType === 'typeA' ? '#0D676C' : '#B52F93',
      colorRange: colorRanges[colorType],
      opacityType: 'literal',
      strokeWidth,
      // data,
      onNearestX: (d) => {
        this.setState({value: d})
      }
    };
    const SVGComponent = showMarks ? LineMarkSeries : LineSeries;
    const CanvasComponent = showMarks ? LineMarkSeriesCanvas : LineSeriesCanvas;

    const text = <span>Title</span>;
    const content = (
      <div>
        <p>Content</p>
        <p>Content</p>
      </div>
    );

    const columnsCliente = [
      {
        title: '',
        dataIndex: '',
        key: '',
        render: (text, record, index)=>{return index + 1 }
      },
        {
        title: 'Ruc',
        dataIndex: 'ruc',
        key: 'ruc',
        width: 100
      }, {
        title: 'Razón Social',
        dataIndex: 'nombre',
        key: 'nombre',
      }, {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: 70,
        render: (text,record, index) => {
          return (
              <Popover placement="left"
                title={"TOTAL: $"+Number(text).toFixed(2)}
                content={this.getComponentTotal(record.totalBancos)}>
                <a>
                  {"$"+Number(text).toFixed(2)}
                </a>
              </Popover>
            )
          }
      }];
      // <Col  lg={6} md={6} sm={12}>
      //   <Card
      //     title={"Consulta de Orden de Pago"}
      //     content={
      //       <WrapperFormRecaudacion
      //         wrappedComponentRef={this.saveFormOP}
      //         emitOP={this.consultaOP} />
      //     }
      //   />
      // </Col>

      // <XYPlot height={300} width={300}
      //   onMouseLeave={() => this.setState({value: false})}>
      //   <VerticalGridLines />
      //   <HorizontalGridLines />
      //   <XAxis />
      //   <YAxis />
      //   <CanvasComponent {...lineSeriesProps} />
      //   <VerticalBarSeries data={data} />
      //   {value && <Crosshair values={[value]} />}
      // </XYPlot>

      // itemsFormat={(d) => [{title: 'Total', value: '$ '+d[0].y}]}/>
      // <Card
      //   statsIcon="fa fa-history"
      //   id="chartHours"
      //   title="Recaudado"
      //   category="Total de pagos registrados"
      //   stats={range.label}
      //   content={
      //     <div className="ct-chart">
      //       <ChartistGraph
      //         data={graphPagos.data}
      //         type="Line"
      //         options={graphPagos.options}
      //         responsiveOptions={graphPagos.responsive}
      //       />
      //     </div>
      //   }
      //   legend={
      //     <div className="legend">{this.createLegend(graphPagos.legends)}</div>
      //   }
      // />

      // <Row>
      //   <Col md={12} sm={12}>
      //     <Card
      //       statsIcon="fa fa-history"
      //       id="chartHours"
      //       title="Transacciones"
      //       category="Total de transacciones realizadas"
      //       stats={range.label}
      //       content={
      //         <div className="ct-chart">
      //           <ChartistGraph
      //             data={graphTransacciones.data}
      //             type="Line"
      //             options={graphTransacciones.options}
      //             responsiveOptions={graphTransacciones.responsiveSales}
      //           />
      //         </div>
      //       }
      //       legend={
      //         <div className="legend">{this.createLegend(graphTransacciones.legends)}</div>
      //       }
      //       />
      //   </Col>
      // </Row>
    return (
      <div className="content">
        <Grid fluid>
          <Row className="form-small">

            <Col md={6} lg={6} sm={12}>
              <Row style={{marginBottom:10}} className="form-small">
                <Col lg={6} md={6} sm={6}>
                  <Select defaultValue={range.value} style={{ width: 200 , marginRight:10}} onChange={this.handleChange}>
                        {ranges.map( r => {
                          return (<Option key={r.value} value={r.value}>{r.label}</Option>)
                        })}
                  </Select>
                </Col>
                <Col lg={6} md={6} sm={6}>

                  <Select defaultValue={0} style={{ width: 300 }} onChange={this.handleChangeBanco}>
                        <Option key={0} value={0}>Todos los bancos</Option>
                        {bancos.map( b => {
                          return (<Option key={b.id_entidad_bancaria} value={b.id_entidad_bancaria}>{b.nombre_corto}</Option>)
                        })}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={4} xs={12}>
                  <StatsCard
                    bigIcon={<i className="pe-7s-server text-warning" />}
                    statsText="Pagos hechos"
                    statsValue={totalTransacciones.pagos - totalTransacciones.reversos}
                    statsIcon={<i className="fa fa-refresh" />}
                    statsIconText={range.label}
                  />
                </Col>
                <Col lg={6} md={6} sm={8} xs={12}>
                  <StatsCard
                    bigIcon={<i className="pe-7s-wallet text-success" />}
                    statsText="Recaudado"
                    statsValue={"$"+totalPagos}
                    statsIcon={<i className="fa fa-calendar-o" />}
                    statsIconText={range.label}
                  />
                </Col>
              </Row>
            </Col>

          </Row>
          <Row>
            <Col md={7} sm={12}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Total Recaudado"
                category="Total de pagos registrados"
                stats={range.label}
                content={
                  <div>
                    <Grid fluid>
                      <Row  style={{height:550}}>
                        <Col md={12}>
                          <FlexibleXYPlot margin={{left: 50}}  height={500}
                             xType="time"
                            onMouseLeave={() => this.setState({value: false})}>
                            <VerticalGridLines />
                            <HorizontalGridLines />

                            <CanvasComponent {...lineSeriesProps}
                              data={ botonBancoSeleccionado==0 || bancoSeleccionado.length == 1? graphPagosTotal.data:graphPagosTotal.getDataBanco(botonBancoSeleccionado)} />
                            <VerticalBarSeries
                              data={ botonBancoSeleccionado==0 || bancoSeleccionado.length == 1? graphPagosTotal.data:graphPagosTotal.getDataBanco(botonBancoSeleccionado)} />
                            {value &&
                              <Crosshair values={[value]}
                                        titleFormat={(d) => ({title: 'Día', value: timeFormat('%d/%m/%Y')(new Date(d[0].x))})}
                                        itemsFormat={(d) => {return this.getItem(d)} }/>
                            }
                            <XAxis title="Fecha" tickFormat={v => {
                                                          var formatTime = timeFormat("%d %b");
                                                          return  formatTime(new Date(v)); // "June 30, 2015"
                                                          }}/>
                            <YAxis title="Recaudado ($)"/>
                          </FlexibleXYPlot>
                        </Col>
                      </Row>
                      {bancoSeleccionado.length>1 &&
                        <Row>
                          <Col md={12} style={{textAlign: "center"}}>
                                <Radio.Group  value={botonBancoSeleccionado} onChange={this.handleBotonBancoChange}>
                                  <Radio.Button key={0} value={0}>Todos</Radio.Button>
                                  {bancoSeleccionado.map((banco,index)=>{
                                      return <Radio.Button key={index} value={banco.id}>{banco.value}</Radio.Button>
                                    })
                                  }
                                </Radio.Group>
                            </Col>
                        </Row>
                      }
                      {bancoSeleccionado.length==1 &&
                        <Row>
                          <Col md={12} style={{textAlign: "center"}}>
                            {bancoSeleccionado[0].value}
                          </Col>
                        </Row>
                      }
                    </Grid>
                  </div>
                }
              />
            </Col>
            <Col md={5} sm={12}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Top 5 de Clientes"
                category="Pagos de clientes"
                stats={range.label}
                content={
                  <Table className="table-small" size="small" dataSource={topClientes.clientes}
                    pagination={false}
                    showHeader={false}
                    columns={columnsCliente} />
                }
              />

            </Col>
          </Row>

        </Grid>
        <Modal
          width={450}
          title = {"Respuesta de consulta"}
          onCancel={this.handleCancel}
          visible={visibleResponse}
          footer={[
            <Button key="back" onClick={this.handleOk}>Cerrar</Button>,
          ]}
        >
            <RowA>
               {this.state.error.show?
                 <Alert message={this.state.error.message} type="error" />
               :''}
               {responseConsulta!=undefined &&
                    <ColA xs={24} md={24}>
                      <CardA
                        title={<span>Datos de consulta {<span className="tituloValor">$ {parseFloat(responseConsulta.Valor_Minimo_Cobro).toFixed(2)}</span>}</span>}
                        >
                        <p>Cod.Obligacion: {responseConsulta.Cod_Obligacion}</p>
                        <p>Nombre Cliente: {responseConsulta.Nombre_Cliente}</p>
                        <p>Valor Total a Pagar: {responseConsulta.Nombre_Cliente}</p>
                        <p>Tipo Producto: {responseConsulta.Tipo_Producto}</p>
                        <p>Nro Cobros: {responseConsulta.Nro_Cobros}</p>
                        <p>Valor Minimo Cobro: $ {parseFloat(responseConsulta.Valor_Minimo_Cobro).toFixed(2)}</p>
                        <p>Referencia: {responseConsulta.Referencia}</p>
                      </CardA>
                    </ColA>
              }
            </RowA>
        </Modal>

      </div>
    );
  }
}

export default Dashboard;
