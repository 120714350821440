import React from "react";
import { HashRouter, Route, Switch,Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import indexRoutes from "routes/index.jsx";
import asyncComponent from "./helpers/AsyncFunc";
import Login from "views/Login/Login.jsx";



const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path={"/login"}
          name="Login"
          component={asyncComponent(() => import("views/Login/Login.jsx"))}
        />
        <RestrictedRoute
            path="/"
            name="Admin"
            component={asyncComponent(() => import("layouts/Dashboard/Dashboard.jsx"))}
            isLoggedIn={isLoggedIn}
          />
      </Switch>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
