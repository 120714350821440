export default class GraphData {
  // Data for Line Chart
  monthLabels =  [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic"
  ];
  constructor(data,type,legends,unitY){
    console.log("CONSTRUCT 1")
    var dataArr = [[],[],1];
    var labelY = unitY? unitY+" ":'';

    if(type === "REACAUDADO") dataArr = this.parseDataPagos(data);
    else if(type === "TRANSACCIONES") dataArr = this.parseDataTransacciones(data);
    this.data = {
      labels: dataArr[1],
      series: dataArr[0]
    };
    this.options = {
      low: 0,
      high: dataArr[2],
      showArea: false,
      height: "245px",
      axisX: {
        showGrid: false,
        divisor: 5,
      },
      axisY: {
        offset: 80,
        labelInterpolationFnc: function(value) {
          return labelY+value
        },
        scaleMinSpace: 15
      },
      lineSmooth: true,
      showLine: true,
      showPoint: true,
      fullWidth: true,
      chartPadding: {
        right: 50
      }
    };
    this.responsive = [
      [
        "screen and (max-width: 640px)",
        {
          axisX: {
            labelInterpolationFnc: function(value) {
              return value[0];
            }
          }
        }
      ]
    ];
    this.legends = {
      names: legends,
      types: ["info","danger","warning"]
    };
  }

  parseDataPagos( data ){
    let totales = [];
    let fechas = [];
    var lower = 0;
    var max = 0;
    data.map(pago=>{
      var d = this.parseDate(pago.fecha);
      var total = Number(pago.total);
      totales.push(total);
      fechas.push(this.formatDate(d));
      if(total >= max)max = total;
    });
    return [[totales],fechas,max];
  }
  parseDataTransacciones( data ){
    let consultasArr = [];
    let pagosArr = [];
    let reversosArr = [];
    let fechas = [];
    var lower = 0;
    var max = 0;
    data.map(pago=>{
      var d = this.parseDate(pago.fecha);
      var consultas = Number(pago.consultas);
      var pagos = Number(pago.pagos);
      var reversos = Number(pago.reversos);
      consultasArr.push(consultas);
      pagosArr.push(pagos);
      reversosArr.push(reversos);
      fechas.push(this.formatDate(d));
      if(consultas >= max) max = consultas;
      if(pagos >= max) max = pagos;
      if(reversos >= max) max = reversos;
    });
    return [[consultasArr,pagosArr,reversosArr],fechas,max];
  }
  parseDate( fechaStr ){
    var fechaArray = fechaStr.split("-");
    var date = new Date(Number(fechaArray[0]),Number(fechaArray[1])-1,Number(fechaArray[2]),0,0,0);
    return date;
  }
  formatDate(date){
    var dateStr = date.getDate()+" "+this.monthLabels[date.getMonth()];
    return dateStr;
  }

}
