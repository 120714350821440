import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import HeaderLinks from "../Header/HeaderLinks.jsx";

import imagine from "assets/img/sidebar-3.jpg";
import logo from "assets/img/plpagos_icon.png";

import dashboardRoutes from "routes/dashboard.jsx";

import { getEmpresa } from "../../network/restClient.js";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      empresa:""
    };
  }
  activeRoute(routeName) {
    return this.props.history.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    getEmpresa()
    .then(message=>{
      this.setState({empresa:message.empresa.razon_social})
    })
    .catch(err=>{})
  }
  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + imagine + ")"
    };
    // <div className="logo">
    //    <img className="logoSideBar" src={logo} alt="logo_image" />
    //  </div>
    //<div className="sidebar-background" style={sidebarBackground} />
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color="black"
        data-image={imagine}
      >
        <div className="logo">
            <a href="#" className="simple-text logo-mini">
              <div className="logo-img">
                <img src={logo} alt="Something" style={{width:"100%"}}/>
              </div>
            </a>
            <a href="#" className="simple-text logo-normal">
              PAGOS <br />
            <span className="sub-title">{this.state.empresa}</span>
            </a>
        </div>

        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <HeaderLinks /> : null}
            {dashboardRoutes.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : this.activeRoute(prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
