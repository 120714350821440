export default class GraphData2 {
    // Data for Line Chart
      // <BarSeries data={[{x: 2, y: 10}, {x: 4, y: 5}, {x: 5, y: 15}]} />
      // <BarSeries data={[{x: 2, y: 12}, {x: 4, y: 2}, {x: 5, y: 11}]} />
    //
    // monthLabels =  [
    //   "Ene",
    //   "Feb",
    //   "Mar",
    //   "Abr",
    //   "May",
    //   "Jun",
    //   "Jul",
    //   "Ago",
    //   "Sep",
    //   "Oct",
    //   "Nov",
    //   "Dic"
    // ];
    constructor(dataTotales,dataPorBancos,bancos){
      this.data = this.parseDataPagos(dataTotales);
      this.dataBancos = [];
      this.bancos = [];
      this.minTime = new Date().getTime();
      this.maxTime = new Date().getTime();
  
      if(this.data.length>0){
        this.minTime = this.data[0].x;
        this.maxTime = this.data[this.data.length-1].x;
      }
      bancos.map(b=>{
        var tmp = {
          id:b.id_entidad_bancaria,
          ruc:b.ruc,
          razon_social:b.razon_social,
          nombre_corto:b.nombre_corto
        }
        this.bancos.push(tmp);
      })
      dataPorBancos.map( banco =>{
        var temp = {idBanco:null,data:[]};
        temp.idBanco = banco.id_banco;
        temp.data = this.parseDataPagos(banco.ordenes_pago);
        this.dataBancos.push(temp);
      });
    }
  
    parseDataPagos( data ){
      var dataTemp = [];
      data.map(pago=>{
        var d = this.parseDate(pago.fecha);
        var total = Number(pago.total);
        dataTemp.push({x: d.getTime(), y: total});
      });
      return dataTemp;
    }
    parseDate( fechaStr ){
      var fechaArray = fechaStr.split("-");
      var date = new Date(Number(fechaArray[0]),Number(fechaArray[1])-1,Number(fechaArray[2]),0,0,0);
      return date;
    }
    formatDate(date){
      var dateStr = date.getDate()+" "+this.monthLabels[date.getMonth()];
      return dateStr;
    }
    getData(puntoX){
      var data = [];
      this.dataBancos.map(dataBanco=>{
        var banco = this.getBanco(dataBanco.idBanco);
        var punto = dataBanco.data.find(function(o){if(o.x==puntoX)return o;});
        var temp = {title:"Banco "+dataBanco.idBanco,value:"$0"};
        if(banco!=undefined){
          temp.title = banco.nombre_corto;
        }
        if(punto !=undefined){
          temp.value = "$"+punto.y;
        }
        data.push(temp);
      });
      return data;
    }
    getBanco(id){
      return this.bancos.find(function(o){if(o.id==id)return o;});
    }
    getDataBanco(id){
      var data = {};
      var data = this.dataBancos.find(function(b){if(b.idBanco==id)return b;});
      if(data!=undefined)
        return data.data;
      else
        return [];
    }
  }
  