import axios from 'axios';
import { clearToken } from '../helpers/utility';

var instanceAxios = axios.create({
   baseURL: 'https://pagos.portalogistico.ec/banco-api'
  // baseURL: 'http://localhost:5000'
});


instanceAxios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(error.response.status === 403) {
      clearToken();
      window.location.href = "/login?expired=true"
    }else{
      throw error;
    };
});

function getResumeTransacciones(range,id_bancos) {
  testToken();
  var body = {
    range:range,
    id_bancos:id_bancos.map((a)=>{return a.id})
  }
  return instanceAxios.post('/dashboard/transacciones',body)
	  .then(response => response.data)
	  .catch(error=> error.response);
}

function getResumeRecaudado(range,id_bancos) {
  testToken();
  var body = {
    range:range,
    id_bancos:id_bancos.map((a)=>{return a.id})
  }
  return instanceAxios.post('/dashboard/ordenes-pagos',body)
	  .then(response => response.data)
	  .catch(error=> error.response);
}

function getFiveClientes(range,id_bancos) {
  testToken();
  var body = {
    range:range,
    id_bancos:id_bancos.map((a)=>{return a.id})
  }
  return instanceAxios.post('/dashboard/clientes',body)
	  .then(response => response.data)
	  .catch(error=> error.response);
}

// {"fecha_desde_registro": "2018-20-01 00:00:00","fecha_hasta_registro": "","id_transaccion":"",
// 	"id_metodos":[],"codigo_orden":"","id_empresas":[],
// "request":"","response":"","estado":true,
// "order_by":{"index":1,"order":"desc"},
// 	"size":10,"page":1 }
// ORDER BY
// 'id','metodo', 'empresa','tiempo_ejecucion','estado',
// 'fecha_contable','fecha_registro'


// instanceAxios.interceptors.response.use(function (response) {
//     return response;
//   }, function (error) {
//     if(error.response.status === 403) {
//       localStorage.removeItem('id_token');
//       localStorage.removeItem('profile');
//       return window.location.href = '/';
//       //throw Error(error.response.data.message);
//     }else{
//       throw Error(error.response.data.message);
//     };
// });

function adminLogin(userForm) {
  return instanceAxios.post('/users/login', {
	    usuario: userForm.usermail,
	    contrasena: userForm.password
	  })
}

function getTransacciones(filtros) {
  testToken();
  var f = iterationCopy(filtros);
  if(f.fecha_desde_registro)
      f.fecha_desde_registro += " 00:00:00";
  if(f.fecha_hasta_registro)
      f.fecha_hasta_registro += " 23:59:59";

  var bool=null;
  if(filtros['estado'])
    bool = filtros['estado']==='t'? true:false;

  f.estado = bool;
  return instanceAxios.post('/transacciones/transactionAll',f)
	  .then(response => response.data)
	  .catch(error=> error.response);
}

function getMetodos() {
  testToken();
  return instanceAxios.get('/transacciones/metodos')
	  .then(response => response.data)
	  .catch(error=> error.response);
}

function getBancos() {
  testToken();
  return instanceAxios.get('/bancos/')
	  .then(response => response.data)
	  .catch(error=> error.response);
}

function getOPbyRecaudacion(tipo_producto,codigo_obligacion) {
  testToken();
  var body = {
    codigo_obligacion: codigo_obligacion,
    tipo_producto : tipo_producto,
    taller: false
  }
  return instanceAxios.post('/recaudacion/consulta',body)
	  .then(response => response.data)
	  .catch(error=> error.response);
}

// {	"fecha_desde": "", "fecha_hasta": "", "ruc_cliente":"", "codigo_orden":"",
// "id_empresas": [] , "id_empresa": null,
// "razon_social_cliente":"","estado":"","order_by":{"index":4,"order":"desc"},
// 	"size":20,"page":0}
// ORDER BY: 1 -> op.id_orden_pago
// ORDER BY: 4 -> op.razon_social_cliente
// ORDER BY: 5 -> op.valor
// ORDER BY: 6 -> op.estado
// ORDER BY: 8 -> empresa
function getOrdenesPago(filtros) {
  testToken();
  var f = iterationCopy(filtros);
  if(f.fecha_desde)
      f.fecha_desde += " 00:00:00";
  if(f.fecha_hasta)
      f.fecha_hasta += " 23:59:59";

  return instanceAxios.post('/ordenes-pago/all',f)
	  .then(response => {
      return response.data;
    })
	  .catch(error=> error.response);
}

function checkoutOrdenPago(id_op,check) {
  testToken();
  const body = {id_orden_pago:id_op,checkout:check};
  return instanceAxios.post('/ordenes-pago/checkout',body)
	  .then(response => response.data)
	  .catch(error=> error.response);
}

// function getOrdenesPago(filtros) {
//   testToken();
//   var f = iterationCopy(filtros);
//   if(f.fecha_desde)
//       f.fecha_desde += " 00:00:00";
//   if(f.fecha_hasta)
//       f.fecha_hasta += " 23:59:59";
//
//   return instanceAxios.post('/ordenes-pago/all',f)
// 	  .then(response => response.data)
// 	  .catch(error=> error.response);
// }

// {	"fecha_desde": "", "fecha_hasta": "", "ruc_cliente":"",
// 	"razon_social_cliente":"","estado":"","order_by":{"index":4,"order":"desc"},
// 	"size":20,"page":0}
function getClientes(filtros) {
  testToken();
  var f = iterationCopy(filtros);
  if(f.fecha_desde)
      f.fecha_desde += " 00:00:00";
  if(f.fecha_hasta)
      f.fecha_hasta += " 23:59:59";

  return instanceAxios.post('/ordenes-pago/clientes',f)
	  .then(response => response.data)
	  .catch(error=> error.response);
}

function getEmpresas() {
  testToken();
  return instanceAxios.get('/empresas')
	  .then(response => {
      console.log(response);
      return response.data}
    ).catch(error=> {
      console.log(error);
      return error.response
    });
}

function getEmpresa() {
  testToken();
  return instanceAxios.get('/empresas/empresa')
	  .then(response => response.data)
	  .catch(error=> error.response);
}

function getCodigoContratos() {
  testToken();
  return instanceAxios.get('/empresas/codigos')
	  .then(response => response.data)
	  .catch(error=> error.response);
}

function testToken(){
  instanceAxios.defaults.headers.common['Authorization'] = localStorage.getItem('id_token');
  instanceAxios.defaults.headers.common['Time-Offset'] = (new Date().getTimezoneOffset() ) /60;
}

function iterationCopy(src) {
  let target = {};
  for (let prop in src) {
    if (src.hasOwnProperty(prop)) {
      target[prop] = src[prop];
    }
  }
  return target;
}
function getBancosEmpresa() {
  testToken();
  return instanceAxios.get('/compensacion/bancos')
	  .then(response => response.data)
	  .catch(error=> error.response);
}
function getDetalleCompensacion(consulta) {
  testToken();
  return instanceAxios.post('/compensacion/consulta',consulta)
	  .then(response => response.data)
}

export {getTransacciones,getOrdenesPago,getClientes,getEmpresas,getBancos,getEmpresa,getMetodos,
  getCodigoContratos,checkoutOrdenPago,getOPbyRecaudacion,getResumeTransacciones,getResumeRecaudado,
  getFiveClientes,adminLogin,getBancosEmpresa,getDetalleCompensacion};
