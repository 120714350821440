const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  TIMEOUT:0,
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (user) => ({
    t:user.t,
    type: actions.LOGIN_REQUEST,
    token:user.token,
    profile:user
  }),
  logout: () => ({
    type: actions.LOGOUT
  })
};
export default actions;
