import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import Icon from 'antd/lib/icon';
import { connect } from 'react-redux';

import authAction from '../../redux/auth/actions';
const { logout } = authAction;

class HeaderLinks extends Component {
  menuSelect = (key)=>{
    console.log(key);
    if(key==2.1){
      this.props.logout();
    }
  }
  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">5</span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );

    return (
      <div>
        <Nav>
          <NavItem eventKey={1} href="#">
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>

        </Nav>
        <Nav pullRight onSelect={this.menuSelect}>
          <NavDropdown
            eventKey={2}
            title={<span><Icon style={{marginRight:'10px'}} type="user" />{this.props.profile?this.props.profile.usuario:""}</span>}
            id="basic-nav-dropdown-right"
          >
            <MenuItem eventKey={2.1}>Cerrar sesión</MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}

export default connect(
  state => ({
    profile: state.Auth.profile,
  }),
  {logout}
)(HeaderLinks);
