import React, { Component } from 'react';


import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import message from 'antd/lib/message';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Alert from 'antd/lib/alert';


import { connect } from 'react-redux';
import authAction from '../../redux/auth/actions';
import { Link, Redirect } from 'react-router-dom';
import logo from "../../assets/img/plpagos_icon.png";
import bglogin from "../../assets/img/bglogin.jpg";
import {adminLogin} from '../../network/restClient';
//import queryString from 'query-string';
const { login } = authAction;
var { TIMEOUT } = authAction;
const FormItem = Form.Item;

const styleBackGround={
  background: `url(${bglogin}) no-repeat center center fixed`,
  backgroundSize: `cover`,
  height: '100vh',
  width: '100%',
  position: 'fixed',
  opacity: '0.3',
}

const styleFormLogin={
  width: '80%',
  margin: 'auto',
  maxWidth: '400px',
  background: 'white',
  padding: '20px',
  borderRadius: '5px',
  border:'1px solid #007079',
  position:'relative'
}

class LoginObj extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      error:"",
    };
  }

  componentDidMount(){
    // const value=queryString.parse(this.props.location.search);
    // if(value.expired && this.props.isLoggedIn==false){
    //   notification.open({
    //     message: 'Sesión Expirada',
    //     description: 'Vuelva a iniciar sesión',
    //   });
    // }
  }

  handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const userForm = {
          usermail: values.userName,
          password: values.password
        }
        this.setState({loading:true,error:""})

        adminLogin(userForm).then(response=>{
          TIMEOUT = response.data.user.t;
          this.props.login(response.data.user);
        }).catch(error=>{
          // let message = error.response.data.log;
          let message = "";
          if(error.response!=undefined){
            message = error.response.data.log;
          }
          this.setState({loading:false,error:message})
        })
        }
      });
    }

  render() {
    const from = { pathname: '/dashboard' };
    const { redirectToReferrer } = this.state;
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      return <Redirect to={from} />;
    }
    const { getFieldDecorator } = this.props.form;
    return (
        <div className="contenedorLogin">
          <div style={styleBackGround}></div>
          <div style={styleFormLogin}>
            <div className="logoLogin"><img src={logo}/></div>
            {this.state.error!=""?<Alert message={this.state.error} type="error" />:""}
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator('userName', {
                  rules: [{ required: true, message: 'Por favor, ingrese su usuario' }],
                })(
                  <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nombre de usuario" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Por favor, ingrese su contraseña!' }],
                })(
                  <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Contraseña" />
                )}
              </FormItem>
              <FormItem>
                <Button loading={this.state.loading} htmlType="submit" type="primary" className="login-form-button">
                  Ingresar
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
    );
  }
}

const Login = Form.create()(LoginObj);
export default connect(
  state => ({
    isLoggedIn: state.Auth.idToken !== null,
  }),
  { login }
)(Login);
