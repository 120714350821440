export default class TransaccionObj  {
  constructor(object) {
    this.key = object.id_transaccion?Number(object.id_transaccion):null;
    this.idMetodo = object.id_metodo?Number(object.id_metodo):null;
    this.metodo = object.metodo?object.metodo:"";
    this.banco = object.nombre_corto_banco?object.nombre_corto_banco:"";
    this.idOrdenPago = object.id_orden_pago?Number(object.id_orden_pago):null;
    this.codigoOrden = object.codigo_orden?object.codigo_orden:"";
    this.idEmpresa = object.id_empresa?Number(object.id_empresa):null;
    this.empresa = object.empresa?object.empresa:"";
    this.jsonResquest = object.json_resquest?object.json_resquest:"";
    this.jsonResponse = object.json_response?object.json_response:"";
    this.tiempoEjecucion = Number(object.tiempo_ejecucion);
    this.estado = object.estado?object.estado:false;
    this.fechaRegistro = object.fecha_registro?object.fecha_registro:"";
    this.fechaTransaccion = object.fecha_transaccion?object.fecha_transaccion:"";
    this.fechaContable = object.fecha_contable?object.fecha_contable:"";
    this.estadoLabel = this.getEstadoLabel();
  }

  getEstadoLabel(){return this.estado? "COMPLETO":"INCOMPLETO";}
}
