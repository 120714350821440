export default class TopClientes {
    constructor(clientes,bancos){
        this.clientes = [];
        clientes.map(cl=>{
          var cliente = {nombre:"",ruc:"",total:null,totalBancos:[]};
          cliente.nombre = cl.razon_social;
          cliente.ruc = cl.ruc;
          cliente.total = Number(cl.total);
          cl.total_bancos.map(b=>{
            var totalBanco = {total:null,banco:""};
            var banco = bancos.find(function(bn){if(bn.id_entidad_bancaria == b.id_banco)return bn});
            totalBanco.total = Number(b.total);
            if(banco)totalBanco.banco = banco.nombre_corto;
            cliente.totalBancos.push(totalBanco);
          });
          this.clientes.push(cliente);
        })
    }
    getTotal(index){
        var cliente = this.clientes[index];
        return cliente.totalBancos;
    }
    
  }
  