import Clientes from "views/Clientes/Clientes";
import Dashboard from "views/Dashboard/Dashboard";
import OrdenPago from "views/OrdenPago/OrdenPago";
import Transacciones from "views/Transacciones/Transacciones";
import Conciliacion from "views/Conciliacion/Conciliacion";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  {
    path: "/ordenes-pago",
    name: "Pagos recibidos",
    icon: "pe-7s-notebook",
    component: OrdenPago
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: "pe-7s-user",
    component: Clientes
  },
  {
    path: "/transacciones",
    name: "Transacciones",
    icon: "pe-7s-repeat",
    component: Transacciones
  },
  {
    path: "/conciliacion",
    name: "Conciliación",
    icon: "pe-7s-wallet",
    component: Conciliacion
  },
  { redirect: true, path: "/", to: "/dashboard", name: "Dashboard" }
];
export default dashboardRoutes;
