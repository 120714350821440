import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import es_ES from 'antd/lib/locale-provider/es_ES';

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";


import Table from 'antd/lib/table';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import DatePicker from 'antd/lib/date-picker';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import Tag from 'antd/lib/tag';

import { getOrdenesPago , getEmpresas , getBancos} from '../../network/restClient.js';
import OrdenPagoObj from '../OrdenPago/Model.js';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const cltion = Select.cltion;

class OPClientes extends Component {
  constructor(prcls) {
    super(prcls);
    this.state = {
      searchText: '',
      loading:false,
      loadingEmpresas:false,
      ordenesPago: [],
      empresas: [],
      totalOP:0,
      size:10,
      page:1,
      filterEmpresas: [],
      filterBancos: [],
      fields: {
        fecha_desde:"",
        fecha_hasta:"",
        ruc_cliente:this.props.rucCliente,
        codigo_orden:"",
        id_empresa:null,
        id_empresas:[],
        id_bancos:[],
        razon_social_cliente:"",
        estado:"",
      	order_by:{index:"id",order:"desc"}
      }
    };
  }

  componentDidMount(){
    this.loadOrdenesPago();
    this.loadBancos();
    // this.loadEmpresas();
  }
  loadBancos(){
    getBancos()
      .then(response=>{
          let filterBancos = [];
          response.bancos.map(e=>{
            filterBancos.push({value:e.id_entidad_bancaria,text:e.nombre_corto.toUpperCase()});
          });
          this.setState({filterBancos:filterBancos});
      })
      .catch(error=>{
      })
  }
  loadOrdenesPago = ()=>{
    if(!this.state.loading){
        this.setState({loading:true});
        var filtros = this.state.fields;
        filtros.size = this.state.size;
        filtros.page = this.state.page;

        getOrdenesPago(filtros).then(response=>{
          let ops = []
          response.ordenes_pago.map(op=>{
            ops.push(new OrdenPagoObj(op.id_orden_pago,
                              op.codigo_orden,
                              op.ruc_cliente,
                              op.razon_social_cliente,
                              op.nombre_corto_banco,
                              Number(op.valor),
                              op.estado,
                              op.fecha_registro,
                              op.id_empresa,
                              op.empresa));
          })
          this.setState({ordenesPago:ops,loading:false,totalOP:Number(response.total)});
        }).catch(err=>{
          this.setState({loading:false});
        })
    }
  }

  handleSearchCodigo = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.codigo_orden = selectedKeys[0];
    this.loadOrdenesPago();
  }

  handleResetCodigo = clearFilters => () => {
    clearFilters();
    this.state.fields.codigo_orden = "";
    this.loadOrdenesPago();
  }

  handleSearchRuc = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.ruc_cliente = selectedKeys[0];
    this.loadOrdenesPago();
  }

  handleResetRuc = clearFilters => () => {
    clearFilters();
    this.state.fields.ruc_cliente = "";
    this.loadOrdenesPago();
  }

  handleSearchNombre = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.razon_social_cliente = selectedKeys[0];
    this.loadOrdenesPago();
  }

  handleResetNombre = clearFilters => () => {
    clearFilters();
    this.state.fields.razon_social_cliente = "";
    this.loadOrdenesPago();
  }

  filterEstado = (value) => {
    this.state.fields.estado = value;
    console.log(value)
    //this.loadOrdenesPago();
  }

  clearFilters = () => {
    this.setState({ fields: {
          fecha_desde:this.props.fechaDesde,
          fecha_hasta:this.props.fechaHasta,
          ruc_cliente:this.props.rucCliente,
          codigo_orden:"",
          id_empresas:[],
          id_bancos:null,
          razon_social_cliente:"",
          estado:"",
          order_by:this.state.fields.order_by
        } },()=>{
          this.loadOrdenesPago();
        });
  }

  handleTableChange = (pagination, filters, sorter) => {
      console.log(filters,sorter)
      if(filters['estado']){
        this.state.fields.estado = filters['estado'][0];
      }
      if(filters['empresa']){
        this.state.fields.id_empresas = filters['empresa'];
      }
      if(filters['banco']){
        this.state.fields.id_bancos = filters['banco'];
      }
      if(sorter.field === "fecha"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"fecha_registro",order:order}
        this.loadOrdenesPago();
      }
      if(sorter.field === "razonSocialCliente"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"cliente",order:order}
        this.loadOrdenesPago();
      }
      if(sorter.field === "empresa"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"empresa",order:order}
        this.loadOrdenesPago();
      }
      if(sorter.field === "valor"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"valor",order:order}
        this.loadOrdenesPago();
      }
      if(sorter.field === "estado"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"estado",order:order}
        this.loadOrdenesPago();
      }
      if(sorter.field === "estado"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"estado",order:order}
        this.loadOrdenesPago();
      }
      if(sorter.field === "banco"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"banco",order:order}
        // this.loadOrdenesPago();
      }
      if(sorter.field==null)this.state.fields.order_by = {index:"id",order:"desc"}
  }

  onChangePage = (page) => {
    this.setState({page: page},()=>{
      this.loadOrdenesPago();
    });
  }

  onResizePage = (current, pageSize) => {
    this.setState({size: pageSize},()=>{
      this.loadOrdenesPago();
    });
  }

  render() {
    const { empresas , filterBancos , filterEmpresas , fields , loading , detalleCliente } = this.state;
    const columns = [
      /* --------------------------- Column CÓDIGO ---------------------------------- */
      {title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      filteredValue: fields.codigo_orden || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => this.searchInput = ele}
            placeholder="Search name"
            value={selectedKeys?selectedKeys[0]:null}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={this.handleSearchCodigo(selectedKeys, confirm)}
          />
          <Button type="primary" onClick={this.handleSearchCodigo(selectedKeys, confirm)}>Buscar</Button>
          <Button onClick={this.handleResetCodigo(clearFilters)}>Borrar</Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            this.searchInput.focus();
          });
        }
      },
      render: (text) => {
        const { fields } = this.state;
        return fields.codigo_orden ? (
          <span>
            {text.split(new RegExp(`(?<=${fields.codigo_orden})|(?=${fields.codigo_orden})`, 'i')).map((fragment, i) => (
              fragment.toLowerCase() === fields.codigo_orden.toLowerCase()
                ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
            ))}
          </span>
        ) : text;
      },
    },
    /* --------------------------- Column NOMBRE BANCO ---------------------------------- */
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      sorter: true,
      filters: filterBancos,
      filteredValue: fields.id_bancos || null,
      filterMultiple: true,
      render: (text,item) => {return text.toUpperCase()}
    }
    ,{
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      sorter: true
    },
    // {
    //   title: 'Empresa',
    //   dataIndex: 'empresa',
    //   key: 'empresa',
    //   sorter: true,
    //   filters: this.props.filterEmpresas,
    //   filteredValue: fields.id_empresas || null,
    //   filterMultiple: true,
    //   render: (text,item) => {return text.toUpperCase()}
    // },
    {
      title: 'Estado',
      dataIndex: 'estado',
      key: 'estado',
      sorter: true,
      filters: [{
          text: 'PAGADO',
          value: 'P',
        }, {
          text: 'REVERSADO',
          value: 'R',
        }],
      filterMultiple: false,
      filteredValue: fields.estado || null,
      render: (text,item) => {return (
        <span>
          {item.estado==='P' && <Tag color="green">{item.getEstadoLabel()}</Tag>}
          {item.estado==='R' && <Tag color="orange">{item.getEstadoLabel()}</Tag>}
        </span>
      )}
    }, {
      title: 'Valor ($)',
      dataIndex: 'valor',
      key: 'valor',
      sorter: true,
      render: (valor) => {return valor.toFixed(2)}
    }];
    return (
      <Row>
          <Col xs={12} md={12} style={{textAlign:"right",paddingBottom:4}}>
            <Button onClick={this.clearFilters} disabled={loading}>Limpiar filtros</Button>
          </Col>
          <Col xs={12} md={12}>
            <Table className="font-table-13" columns={columns}
              size={"small"}
              dataSource={this.state.ordenesPago}
              loading={this.state.loading}
              footer={() => this.state.totalOP+ " órdenes de pago"}
              onChange={this.handleTableChange}
              pagination={{current:this.state.page,
                          showSizeChanger:true,
                          onShowSizeChange:this.onResizePage,
                          onChange:this.onChangePage,
                          total:this.state.totalOP}} />
          </Col>
      </Row>

    )
  }
}
export default OPClientes;
