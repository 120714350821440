import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import es_ES from 'antd/lib/locale-provider/es_ES';

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
// import {Table,Input,Button,Icon,DatePicker,Tag,Form,Select,Tooltip,notification} from 'antd';

import Table from 'antd/lib/table';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import DatePicker from 'antd/lib/date-picker';
import Tag from 'antd/lib/tag';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Tooltip from 'antd/lib/tooltip';
import notification from 'antd/lib/notification';

import { getOrdenesPago , getEmpresas , getBancos , checkoutOrdenPago } from '../../network/restClient.js';
import OrdenPagoObj from './Model.js';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const Option = Select.Option;

const openNotificationWithIcon = (type,title,message) => {
  notification[type]({
    message: title,
    description: message,
    duration: 3,
    placement: 'bottomRight'
  });
};


class OrdenPago extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      loading:false,
      loadingEmpresas:false,
      ordenesPago: [],
      empresas: [],
      totalOP:0,
      size:10,
      page:1,
      filterEmpresas: [],
      filterBancos: [],
      fields: {
        canal: "",
        fecha_desde:"",
        fecha_hasta:"",
        ruc_cliente:"",
        banco:"",
        codigo_orden:"",
        id_empresa:null,
        id_empresas:[],
        id_bancos:[],
        razon_social_cliente:"",
        estado:"",
      	order_by:{index:"id",order:"desc"}
      }
    };
  }

  componentDidMount(){
    this.loadOrdenesPago();
    this.loadEmpresas();
    this.loadBancos();
  }
  loadBancos(){
    getBancos()
      .then(response=>{
          let filterBancos = [];
          response.bancos.map(e=>{
            filterBancos.push({value:e.id_entidad_bancaria,text:e.nombre_corto.toUpperCase()});
          });
          this.setState({filterBancos:filterBancos});
      })
      .catch(error=>{
      })
  }
  loadEmpresas = ()=>{
    this.setState({loadingEmpresas:true});
    getEmpresas().then(response=>{
      let filterEmpresas = [];
      response.empresas.map(e=>{
        filterEmpresas.push({value:e.id_empresa,text:e.razon_social.toUpperCase()});
      })
      this.setState({loadingEmpresas:false,empresas:response.empresas,
                    filterEmpresas:filterEmpresas});
    }).catch(err=>{
      this.setState({loadingEmpresas:false});
    })
  }
  loadOrdenesPago = ()=>{
    if(!this.state.loading){
        this.setState({loading:true});
        var filtros = this.state.fields;
        console.log(filtros)
        filtros.size = this.state.size;
        filtros.page = this.state.page;

        getOrdenesPago(filtros).then(response=>{
          let ops = []
          response.ordenes_pago.map(op=>{
            ops.push(new OrdenPagoObj(op.id_orden_pago,
                              op.codigo_orden,
                              op.ruc_cliente,
                              op.razon_social_cliente,
                              op.nombre_corto_banco,
                              Number(op.valor),
                              op.estado,
                              op.fecha_registro,
                              op.id_empresa,
                              op.empresa,
                              op.revisado,
                              op.canal));
          })
          this.setState({ordenesPago:ops,loading:false,totalOP:Number(response.total)});
        }).catch(err=>{
          this.setState({loading:false});
        })
    }
  }

  handleSearchCodigo = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.codigo_orden = selectedKeys[0];
    this.loadOrdenesPago();
  }

  handleResetCodigo = clearFilters => () => {
    clearFilters();
    this.state.fields.codigo_orden = "";
    this.loadOrdenesPago();
  }

  handleSearchRuc = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.ruc_cliente = selectedKeys[0];
    this.loadOrdenesPago();
  }

  handleResetRuc = clearFilters => () => {
    clearFilters();
    this.state.fields.ruc_cliente = "";
    this.loadOrdenesPago();
  }

  handleSearchNombre = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.razon_social_cliente = selectedKeys[0];
    this.loadOrdenesPago();
  }

  handleResetNombre = clearFilters => () => {
    clearFilters();
    this.state.fields.razon_social_cliente = "";
    this.loadOrdenesPago();
  }

  handleSearchCanal = (selectedKeys, confirm) => () => {
    confirm();
    this.state.fields.canal = selectedKeys[0];
    this.loadOrdenesPago();
  }

  handleResetCanal = clearFilters => () => {
    clearFilters();
    this.state.fields.canal = "";
    this.loadOrdenesPago();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log(values);
        if(values.rangosFecha!= undefined){
          if(values.rangosFecha.length==2){
            this.state.fields.fecha_desde = values.rangosFecha[0].format('YYYY-MM-DD');
            this.state.fields.fecha_hasta = values.rangosFecha[1].format('YYYY-MM-DD');
          }else{
            this.state.fields.fecha_desde = "";
            this.state.fields.fecha_hasta = "";
          }
        }

        this.loadOrdenesPago()
      }
    });
  }

  clearFilters = () => {
    this.setState({ fields: {
          fecha_desde:this.state.fields.fecha_desde,
          fecha_hasta:this.state.fields.fecha_hasta,
          ruc_cliente:"",
          codigo_orden:"",
          id_bancos:null,
          id_empresas:[],
          razon_social_cliente:"",
          estado:"",
          canal:"",
          order_by:this.state.fields.order_by
        } },()=>{
          this.loadOrdenesPago();
        });
  }

  handleTableChange = (pagination, filters, sorter) => {
      /*--------------------    Filtros --------------------- */
      console.log(sorter);
      if(filters['estado']){
        this.state.fields.estado = filters['estado'][0];
      }
      if(filters['empresa']){
        this.state.fields.id_empresas = filters['empresa'];
      }
      if(filters['banco']){
        this.state.fields.id_bancos = filters['banco'];
      }
      /*------------------- Ordenamientos ----------------- */
      if(sorter.field === "fecha"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"fecha_registro",order:order}
        // this.loadOrdenesPago();
      }
      if(sorter.field === "razonSocialCliente"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"cliente",order:order}
        // this.loadOrdenesPago();
      }
      if(sorter.field === "empresa"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"empresa",order:order}
        // this.loadOrdenesPago();
      }
      if(sorter.field === "valor"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"valor",order:order}
        // this.loadOrdenesPago();
      }
      if(sorter.field === "estado"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"estado",order:order}
        // this.loadOrdenesPago();
      }
      if(sorter.field === "canal"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"canal",order:order}
        // this.loadOrdenesPago();
      }
      if(sorter.field === "banco"){
        let order = sorter.order === "descend"?"desc":"asc";
        this.state.fields.order_by = {index:"banco",order:order}
        // this.loadOrdenesPago();
      }

      if(sorter.field==null)this.state.fields.order_by = {index:"id",order:"desc"}

      /*------------------- paginador ----------------- */
          this.state.page = pagination.current;
          this.state.size = pagination.pageSize;

      this.loadOrdenesPago();

  }

  onChangePage = (page) => {
    this.setState({page: page},()=>{
      this.loadOrdenesPago();
    });
  }

  onResizePage = (current, pageSize) => {
    this.setState({size: pageSize},()=>{
      this.loadOrdenesPago();
    });
  }


  checkOrdenPago = (key) => {
    console.log(key);
    const ordenesPago = [...this.state.ordenesPago];
    var op = ordenesPago.filter(item => {return item.key === key});

    op[0].loadingCheck = true;
    this.setState({ ordenesPago: ordenesPago });
    // checkoutOrdenPago
    checkoutOrdenPago(op[0].key,!op[0].revisado).then(response=>{
      op[0].loadingCheck = false;
      op[0].revisado = response.orden_pago.revisado;
      if(response.orden_pago.revisado)
          openNotificationWithIcon('success','Marcado de Orden Pago','Se marcó la OP '+op[0].codigo+' como revisada');
      else
          openNotificationWithIcon('success','Desmarcado de Orden Pago','Se desmarcó la OP '+op[0].codigo);

      this.setState({ ordenesPago: ordenesPago });

    }).catch(err=>{
      op[0].loadingCheck = false;
      openNotificationWithIcon('error','ERROR','No se pudo registrar la marcación');
      this.setState({ ordenesPago: ordenesPago });
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { ordenesPago , filterBancos , empresas , filterEmpresas , fields , loading } = this.state;

    const columns = [
      /* --------------------------- Column CÓDIGO ---------------------------------- */
      {title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      filteredValue: fields.codigo_orden || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => this.searchInput = ele}
            style={{marginBottom:4}}
            placeholder="Buscar name"
            value={selectedKeys? selectedKeys:""}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={this.handleSearchCodigo(selectedKeys, confirm)}
          />
          <Button type="primary" onClick={this.handleSearchCodigo(selectedKeys, confirm)}>Buscar</Button>
          <Button style={{marginLeft:4}} onClick={this.handleResetCodigo(clearFilters)}>Borrar</Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            this.searchInput.focus();
          });
        }
      },
      render: (text) => {
        const { fields } = this.state;
        return fields.codigo_orden ? (
          <span>
            {text.split(new RegExp(`(?<=${fields.codigo_orden})|(?=${fields.codigo_orden})`, 'i')).map((fragment, i) => (
              fragment.toLowerCase() === fields.codigo_orden.toLowerCase()
                ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
            ))}
          </span>
        ) : text;
      },
    },
    /* --------------------------- Column RUC CLIENTE ---------------------------------- */
    {title: 'Ruc Cliente',
      dataIndex: 'rucCliente',
      key: 'rucCliente',
      filteredValue: fields.ruc_cliente || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => this.searchInputRuc = ele}
            style={{marginBottom:4}}
            placeholder="Buscar ruc"
            value={selectedKeys? selectedKeys:""}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={this.handleSearchRuc(selectedKeys, confirm)}
          />
          <Button type="primary" onClick={this.handleSearchRuc(selectedKeys, confirm)}>Buscar</Button>
          <Button style={{marginLeft:4}} onClick={this.handleResetRuc(clearFilters)}>Borrar</Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            this.searchInputRuc.focus();
          });
        }
      },
      render: (text) => {
        const { fields } = this.state;
        return fields.ruc_cliente ? (
          <span>
            {text.split(new RegExp(`(?<=${fields.ruc_cliente})|(?=${fields.ruc_cliente})`, 'i')).map((fragment, i) => (
              fragment.toLowerCase() === fields.ruc_cliente.toLowerCase()
                ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
            ))}
          </span>
        ) : text;
      }
    },
    /* --------------------------- Column NOMBRE CLIENTE ---------------------------------- */
    {title: 'Nombre Cliente',
      dataIndex: 'razonSocialCliente',
      key: 'razonSocialCliente',
      sorter: true,
      filteredValue: fields.razon_social_cliente || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => this.searchInputNombre = ele}
            style={{marginBottom:4}}
            placeholder="Buscar nombre"
            value={selectedKeys? selectedKeys:""}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={this.handleSearchNombre(selectedKeys, confirm)}
          />
          <Button type="primary" onClick={this.handleSearchNombre(selectedKeys, confirm)}>Buscar</Button>
          <Button style={{marginLeft:4}} onClick={this.handleResetNombre(clearFilters)} block>Borrar</Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            this.searchInputNombre.focus();
          });
        }
      },
      render: (text) => {
        const { fields } = this.state;
        return fields.razon_social_cliente ? (
          <span>
            {text.split(new RegExp(`(?<=${fields.razon_social_cliente})|(?=${fields.razon_social_cliente})`, 'i')).map((fragment, i) => (
              fragment.toLowerCase() === fields.razon_social_cliente.toLowerCase()
                ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
            ))}
          </span>
        ) : text;
      }
    },
    /* --------------------------- Column NOMBRE BANCO ---------------------------------- */
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      sorter: true,
      filters: filterBancos,
      filteredValue: fields.id_bancos || null,
      filterMultiple: true,
      render: (text,item) => {return text.toUpperCase()}
    }
    ,{
      title: 'F. Pago',
      dataIndex: 'fecha',
      key: 'fecha',
      sorter: true
    },
    // {
    //   title: 'Empresa',
    //   dataIndex: 'empresa',
    //   key: 'empresa',
    //   sorter: true,
    //   filters: filterEmpresas,
    //   filteredValue: fields.id_empresas || null,
    //   filterMultiple: true,
    //   render: (text,item) => {return text.toUpperCase()}
    // },
    {
      title: 'Estado',
      dataIndex: 'estado',
      // key: 'estado',
      // sorter: true,
      filters: [{
          text: 'PAGADO',
          value: 'P',
        }, {
          text: 'REVERSADO',
          value: 'R',
        }],
      filterMultiple: false,
      // filteredValue: fields.estado || null,
      render: (text,item) => {return (
        <span>
          {item.estado==='P' && <Tag color="green">{item.getEstadoLabel()}</Tag>}
          {item.estado==='R' && <Tag color="orange">{item.getEstadoLabel()}</Tag>}
        </span>
      )}
    }, {
      title: 'Valor ($)',
      dataIndex: 'valor',
      key: 'valor',
      sorter: true,
      render: (valor) => {return valor.toFixed(2)}
    },
    {
      title: 'Canal',
      dataIndex: 'canal',
      key: 'canal',
      sorter: true,
      filteredValue: fields.canal || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
          <Input
            ref={ele => this.searchInput = ele}
            style={{marginBottom:4}}
            placeholder="Buscar canal"
            value={selectedKeys? selectedKeys:""}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={this.handleSearchCanal(selectedKeys, confirm)}
          />
          <Button type="primary" onClick={this.handleSearchCanal(selectedKeys, confirm)}>Buscar</Button>
          <Button style={{marginLeft:4}} onClick={this.handleResetCanal(clearFilters)}>Borrar</Button>
        </div>
      ),
      filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            this.searchInput.focus();
          });
        }
      },
      render: (text) => {
        const { fields } = this.state;
        return fields.canal ? (
          <span>
            {text.split(new RegExp(`(?<=${fields.canal})|(?=${fields.canal})`, 'i')).map((fragment, i) => (
              fragment.toLowerCase() === fields.canal.toLowerCase()
                ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
            ))}
          </span>
        ) : text;
      },
    }
    ];

    // {
    //   title: '',
    //   dataIndex: '',
    //   key: 'action',
    //   sorter: false,
    //   render: (valor,item) => {
    //     return (item.estado==='P' && (!item.revisado ? (
    //       <span>
    //          <Tooltip placement="topLeft" title="Marcar como revisado">
    //             <Button icon="check" size="small"  shape="circle" onClick={() => !item.loadingCheck && this.checkOrdenPago(item.key)} loading={item.loadingCheck}>
    //             </Button>
    //         </Tooltip>
    //       </span>
    //     ) : <span>
    //         <Tooltip placement="topLeft" title="Desmarcar revisado">
    //           <Button icon="check" size="small" type="primary" shape="circle" onClick={() => !item.loadingCheck && this.checkOrdenPago(item.key)} loading={item.loadingCheck} >
    //           </Button>
    //         </Tooltip>
    //     </span>)
    //   )
    //   }
    // }

    // <FormItem
    //     label={'Empresa'}
    //     required={false}>
    //     {getFieldDecorator('empresa', {})(
    //         <Select
    //           className="anticon-no-margin"
    //           showSearch
    //           style={{ width: 200 }}
    //           placeholder="Seleccione una empresa"
    //         >
    //           <Option key={"emp_all"} value={null}>Todas</Option>
    //           {empresas.map((emp,index)=>{
    //               return (
    //                 <Option key={"emp_"+emp.id_empresa} value={emp.id_empresa}>{emp.razon_social}</Option>
    //               )
    //             })
    //           }
    //         </Select>
    //       )}
    // </FormItem>

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={12} sm={12}>
              <Card
                title="Órdenes de Pago"
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div style={{padding:6}}>
                      <Row style={{marginBottom:6}}>
                          <Col xs={12} md={12}>
                              <Form layout="inline" className="rangoFecha" onSubmit={this.handleSubmit}>
                                <FormItem>
                                  {getFieldDecorator('rangosFecha', {})(
                                    <RangePicker locale={es_ES.DatePicker} format='YYYY/MM/DD'/>
                                  )}
                                </FormItem>

                                <FormItem>
                                  <Button type="primary" htmlType="submit" disabled={loading}>
                                    Consultar
                                  </Button>
                                </FormItem>
                                <Button onClick={this.clearFilters} disabled={loading}>Limpiar filtros</Button>
                              </Form>
                          </Col>
                      </Row>
                      <Row>
                          <Col xs={12} md={12}>
                            <Table className="font-table-13" columns={columns}
                              size={"small"}
                              dataSource={ordenesPago}
                              loading={this.state.loading}
                              footer={() => this.state.totalOP+ " órdenes de pago"}
                              onChange={this.handleTableChange}
                              pagination={{current:this.state.page,
                                          showSizeChanger:true,
                                          total:this.state.totalOP}} />
                        </Col>
                    </Row>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
const WrapperOrdenPago = Form.create()(OrdenPago);
export default WrapperOrdenPago;
