import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { store, history } from './redux/store';
import PublicRoutes from './Routes';
import Boot from './redux/boot';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/style.css";
import "./assets/css/pe-icon-7-stroke.css";
import 'antd/dist/antd.css';


// ReactDOM.render(
//   <ConnectedRouter>
//     <Switch>
//       {indexRoutes.map((prop, key) => {
//         <Route path={prop.path} component={prop.component} key={key} />;
//       })}
//     </Switch>
//
//   </ConnectedRouter>,
//   document.getElementById("root")
// );
const App = () => (
  <Provider store={store}>
    <PublicRoutes history={history} />
  </Provider>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
export default App;
